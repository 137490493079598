<page-nav-ul>
    <page-nav-li [link]="true" path="/home/dashboard" [icon]="true" label="Stampel"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="true" label="API"> </page-nav-li>
    <page-nav-li [link]="false" [icon]="false" label="Métodos"> </page-nav-li>
</page-nav-ul>

<div class="portlet light bordered">
    <div portlet-title>
      <portlet-caption subject="API">
        Métodos disponibilizados pelo Stampel para serem consumidos via API.
      </portlet-caption>
    </div>
    <div class="portlet-body">
        <div *ngFor="let data of apiResponse; let i = index">
            <div class="panel-group accordion faq-content asdkhasd" id="accordion{{ i }}">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <h4 class="panel-title">

                            <!-- Angular: Objeto "data" é declarado em "api.component.ts" e é o resultada da requisição GET HTTP -->
                            <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion{{ i }}" href="#collapse{{ i }}">
                                <b> {{ data?.method }} </b>
                                &nbsp;&nbsp;&nbsp;
                                {{ data?.api }}
                            </a>

                        </h4>
                    </div>
                    <div id="collapse{{ i }}" class="panel-collapse collapse">
                        <div class="panel-body">

                            <p>{{ data?.description }}</p>
                            <br />
                            <hr />
                            <h3>JSON de requisição</h3>
                            
                            <div class="panel-body">
                                <pre> {{ data?.jsonRequest | json }} </pre>
                            </div>

                            <h3>Parâmetros</h3>
                            <div class="table-scrollable">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="15%"> Nome </th>
                                            <th width="15%"> Tipo </th>
                                            <th> Descrição </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <!-- Angular syntax Block -->
                                        <tr *ngFor='let parameter of data?.uriParametersList'>
                                            <td> {{ parameter.name }} </td>
                                            <td> {{ parameter.type }} </td>
                                            <td> {{ parameter.description }} </td>
                                        </tr>
                                        <!-- End of Angular syntax Block -->

                                    </tbody>
                                </table>
                            </div>

                            <!-- Angular syntax Block -->
                            <ng-container *ngFor='let parameter of data?.uriParametersList'> 
                                <ng-container *ngIf='parameter.additionalData !== null'>
                                    <br />
                                    <hr />
                                    <h3> {{ parameter.additionalData.name }}</h3>
                                    <p>
                                        {{ parameter.additionalData.description }}
                                    </p>
                                    <div class="table-scrollable">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="15%"> Nome </th>
                                                    <th width="15%"> Tipo </th>
                                                    <th> Descrição </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor='let item of parameter.additionalData.additionalDataItemsList'>
                                                    <tr>
                                                        <td> {{ item.name }} </td>
                                                        <td> {{ item.type }} </td>
                                                        <td> {{ item.description }} </td>
                                                    </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <!-- End of Angular syntax Block -->

                            <br />
                            <hr />
                            <h3>Retorno</h3>
                            <div class="table-scrollable">
                                <table class="table table-hover">
                                    <thead>
                                        <tr>
                                            <th width="15%"> Nome </th>
                                            <th width="15%"> Tipo </th>
                                            <th> Descrição </th>
                                        </tr>
                                    </thead>

                                    <!-- Angular syntax Block -->
                                    <tbody *ngFor='let item of data?.responseItemList'>
                                        <tr>
                                            <td> {{ item.name }} </td>
                                            <td> {{ item.type }} </td>
                                            <td> {{ item.description }} </td>
                                        </tr>
                                    </tbody>
                                    <!-- End of Angular Block -->

                                </table>
                            </div>

                            <!-- Angular  syntax Block -->
                            <ng-container *ngFor="let parameter of data?.responseItemList">
                                <ng-container *ngIf='parameter.additionalData !== null'>
                                    <br />
                                    <hr />
                                    <h3> {{ parameter.additionalData.name }} </h3>
                                    <p>
                                        {{ parameter.additionalData.description }}
                                    </p>
                                    <div class="table-scrollable">
                                        <table class="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th width="15%"> Nome </th>
                                                    <th width="15%"> Tipo </th>
                                                    <th> Descrição </th>
                                                </tr>
                                            </thead>
                                            <tbody *ngFor='let item of parameter.additionalData.additionalDataItemsList'>
                                                <tr>
                                                    <td> {{ item.name }} </td>
                                                    <td> {{ item.type }} </td>
                                                    <td> {{ item.description }} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <br />
                                </ng-container>
                            </ng-container>
                            <!-- End of Angular syntax block -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
