import { Component, OnInit } from '@angular/core'
import { APIViewModel } from '../interfaces/api.interface'
import { ApiService } from '../services'

@Component({
    selector: 'api-selector',
    templateUrl: './api.component.html',
    providers: [
        ApiService
    ]
})

export class ApiComponent implements OnInit {
    
    public apiResponse: APIViewModel[];

    constructor(private apiService: ApiService) {}

    /* ngOnInit() é chamado após o componente ser inciado.
       Aqui, ele faz uma requisição GET em "https://msgit.bringit.com.br/api" */
    ngOnInit() {
        this.apiService.getApiInformation()
            .subscribe((httpResponse: APIViewModel[]) => { 
                this.apiResponse = httpResponse 
        })
    }
}